import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';
import ImproveYourLife from '../components/ImproveYourLife';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="Weight Control" />

    <div>
      <div className="section image-bg-section">
        <div className="container">
          <div className="content-width-medium">
            <h6 className="subheading">Weight Control</h6>
            <h1 className="no-bottom-space center-mobile">Win the mental game of weight-control</h1><Link to="/pricing/" className="button margin-top w-inline-block">
              <div>Get started</div>
            </Link>
          </div>
        </div>
        <div style={{backgroundImage: 'url("/images/5ddc262c5d412b2c78390c5c_yogapose-in-grand-canyon-weight-loss_t20_X24wE3.jpg")'}} className="image-background bg-image-1" />
        <div className="background-scrim-left" />
      </div>
      <div className="section">
        <div className="container">
          <div className="w-richtext">
            <p>The key to rapid and permanent weight control is in your mind. Your thoughts influence your eating habits, cravings, metabolism, and your attitude toward exercise.</p>
            <p>The WiseGuide App is designed to help you achieve rapid, successful, and permanent weight loss. With an emphasis on love, inner peace, and self-esteem, we will help you tap the power of your mind to achieve your ideal weight quickly and painlessly.</p>
            <p>Start a FREE 7-day trial to experience how The WiseGuide App can help you win the mental game of weight control. Just listen.</p>
          </div>
        </div>
      </div>
    </div>

    <ImproveYourLife />

    <DownloadApp />
  </Layout>
);
